<template>
<ul class="nav nav-pills">
    <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle active" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">{{ $t('deutsche-post-dhl') }}</a>
        <div class="dropdown-menu">
            <router-link class="dropdown-item" to="/settings/carrier/dp-internetmarke">
                {{ $t('dp-internetmarke') }}
            </router-link>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#">{{ $t('dp-internetmarke-packetplus') }}</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#">{{ $t('dhl-location-finder') }}</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#">{{ $t('dhl-shipment-tracking') }}</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#">{{ $t('dhl-deutsche-post-international') }}</a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#">{{ $t('dhl-ecommerce-solutions-europe') }}</a>
        </div>
    </li>
    <li class="nav-item">
        <a class="nav-link" href="#">{{ $t('ups') }}</a>
    </li>
        <li class="nav-item">
        <a class="nav-link" href="#">{{ $t('tnt') }}</a>
    </li>
        <li class="nav-item">
        <a class="nav-link" href="#">{{ $t('dpd-germany') }}</a>
    </li>
        <li class="nav-item">
        <a class="nav-link" href="#">{{ $t('dpd-croatia') }}</a>
    </li>
</ul>
</template>
<script>
export default {
    name: "ComponentCarriersNavigation",
}
</script>