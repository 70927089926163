<template>
   <div>
      <layout-full-width>
         <component-carriers-navigation />
         <div class="row">
            <div class="col-12">
               <h1>{{ $t('dp-internetmarke') }}</h1>
            </div>
         </div>
         <div class="row">
            <div class="col-12">
               <div class="card">
                  <div class="card-body">
                     <h3 class="card-title">{{ $t('portokasse') }}</h3>
                     <p>{{ $t('dp-customer-account') }}</p>
                     <div class="form-group">
                        <label for="serviceStatus">{{ $t('service-status') }}</label>
                        <select class="form-control" id="serviceStatus">
                           <option>{{ $t('enabled') }}</option>
                           <option>{{ $t('disabled') }}</option>
                        </select>
                     </div>
                     <div class="form-group">
                        <label for="email">{{ $t('email-address') }}</label>
                        <input type="email" v-model="email" class="form-control" id="email" aria-describedby="emailHelp" :placeholder="$t('enter-email')">
                     </div>
                     <div class="form-group">
                        <label for="exampleInputPassword1">{{ $t('password') }}</label>
                        <input type="password" v-model="password" class="form-control" id="password" :placeholder="$t('password')">
                     </div>
                     <h6 class="card-subtitle mb-2">{{ $t('terms-and-conditions') }}</h6>
                     <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="stina-terms-and-conditions" checked disabled>
                        <label class="form-check-label" for="stina-terms-and-conditions" v-html="$t('stina-terms-and-conditions')">
                        </label>
                     </div>
                     <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="dp-terms-and-conditions" checked disabled>
                        <label class="form-check-label" for="dp-terms-and-conditions" v-html="$t('dp-terms-and-conditions')">
                        </label>
                     </div>
                     <p>{{ $t('terms-and-condition-confirmed') }}</p>
                     <label>{{ $t('wallet-top-up') }}</label>
                     <div class="input-group mb-3">
                        <input type="text" class="form-control" :placeholder="$t('wallet-top-up-placeholder')" aria-label="Recipient's username" aria-describedby="basic-addon2">
                        <div class="input-group-append">
                           <button class="btn btn-outline-secondary" type="button">{{ $t('wallet-top-up') }}</button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div class="row">
            <div class="col-12">
               <div class="card">
                  <div class="card-body">
                     <h3 class="card-title">{{ $t('dp-internetmarke') }}</h3>
                     <div class="form-group">
                        <h5>{{ $t('service-status') }}</h5>
                        <select class="form-control" id="internetmarkeEnabled">
                           <option>{{ $t('enabled') }}</option>
                           <option>{{ $t('disabled') }}</option>
                        </select>
                        <p>{{ $t('internetmarke.enabled-description') }}</p>
                     </div>
                     <h5>{{ $t('internetmarke.label-options') }}</h5>
                     <h6>{{ $t('internetmarke.manifest') }}</h6>
                     <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="stina-terms-and-conditions">
                        <label class="form-check-label" for="stina-terms-and-conditions">
                           {{ $t('internetmarke.manifest-label') }}
                        </label>
                     </div>
                     <p>{{ $t('internetmarke.manifest-description') }}</p>

                     <div class="form-group">
                        <label for="shippingList">{{ $t('internetmarke.shipping-list') }}</label>
                        <select class="form-control" id="shippingList">
                           <option>{{ $t('internetmarke.no-shipping-list') }}</option>
                           <option>{{ $t('internetmarke.shipping-list-without-addresses') }}</option>
                           <option>{{ $t('internetmarke.shipping-list-with-addresses') }}</option>
                        </select>
                     </div>
                     <h5>{{ $t('internetmarke.page-formats') }}</h5>
                     <h6>{{ $t('internetmarke.page-formats-description') }}</h6>
                     <div class="form-group">
                        <label for="labelFormat">{{ $t('internetmarke.default-label-format') }}</label>
                        <select class="form-control" id="labelFormat">
                        </select>
                        <p>{{ $t('internetmarke.label-format-description') }}</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>


         <div class="row">
            <div class="col-12">
               <div class="card">
                  <div class="card-body">
                     <h3 class="card-title">{{ $t('top-up') }}</h3>
                        <div class="card-body">
                           <h6 class="card-subtitle mb-2 text-muted">{{ $t('on-demand-top-up') }}</h6>
                           <div class="form-check">
                              <input class="form-check-input" type="radio" name="onDemandTopUp" id="onDemandTopUpYes" :value="true" v-model="onDemandTopUp">
                              <label class="form-check-label" for="onDemandTopUpYes">
                              {{ $t('yes') }}
                              </label>
                           </div>
                           <div class="form-check">
                              <input class="form-check-input" type="radio" name="onDemandTopUp" id="onDemandTopUpNo" :value="false" v-model="onDemandTopUp">
                              <label class="form-check-label" for="onDemandTopUpNo">
                              {{ $t('no') }}
                              </label>
                           </div>
                           <div class="checkbox-submenu" v-if="onDemandTopUp">
                              <div class="form-check">
                                 <input class="form-check-input" type="radio" name="onDemandTopUpTrue" id="onDemandTopUpAutomated" value="automatic" v-model="onDemandTopUpTrue">
                                 <label class="form-check-label" for="onDemandTopUpAutomated">
                                 {{ $t('automatic') }}
                                 </label>
                              </div>
                              <div class="form-check">
                                 <input class="form-check-input" type="radio" name="onDemandTopUpTrue" id="onDemandTopUpOnRequest" value="onRequest" v-model="onDemandTopUpTrue">
                                 <label class="form-check-label" for="onDemandTopUpOnRequest">
                                 {{ $t('on-request') }}
                                 </label>
                              </div>
                           </div>
                        </div>
                        <div class="card-body">
                           <h6 class="card-subtitle mb-2 text-muted">{{ $t('automated-top-up') }}</h6>
                           <div class="form-check">
                              <input class="form-check-input" type="radio" name="automatedTopUp" id="automatedTopUpYes" value="true" v-model="automatedTopUp">
                              <label class="form-check-label" for="automatedTopUpYes">
                              {{ $t('yes') }}
                              </label>
                           </div>
                           <div class="form-check">
                              <input class="form-check-input" type="radio" name="automatedTopUp" id="automatedTopUpNo" :value="false" v-model="automatedTopUp">
                              <label class="form-check-label" for="automatedTopUpNo">
                              {{ $t('no') }}
                              </label>
                           </div>
                        </div>
                        <div class="card-body" style="padding-top: 0px;" v-if="automatedTopUp">
                           <div class="form-group">
                              <label for="treshold">{{ $t('treshold-euro') }}</label>
                              <input type="number" min="0" v-model="treshold" class="form-control" id="treshold" :placeholder="$t('enter-treshold-euro')">
                           </div>
                           <div class="form-group">
                              <label for="topUpAmmunt">{{ $t('top-up-amount-euro') }}</label>
                              <input type="number" min="0" v-model="automatedTopUpAmount" class="form-control" id="topUpAmount" :placeholder="$t('enter-top-up-amount-euro')">
                           </div>
                           <div class="form-group">
                              <label for="limitPer">{{ $t('limit-per') }}</label>
                              <table class="table">
                                 <thead>
                                    <tr>
                                       <th scope="col">{{ $t('day') }}</th>
                                       <th scope="col">{{ $t('week') }}</th>
                                       <th scope="col">{{ $t('month') }}</th>
                                       <th scope="col">{{ $t('year') }}</th>
                                    </tr>
                                 </thead>
                                 <tbody class="table-body">
                                    <tr>
                                       <td><input type="number" min="0" v-model="dayLimitAmount" class="form-control" id="limitAmout" :placeholder="$t('enter-limit-amount')"></td>
                                       <td><input type="number" min="0" v-model="weekLimitAmount" class="form-control" id="limitAmout" :placeholder="$t('enter-limit-amount')"></td>
                                       <td><input type="number" min="0" v-model="monthLimitAmount" class="form-control" id="limitAmout" :placeholder="$t('enter-limit-amount')"></td>
                                       <td><input type="number" min="0" v-model="yearLimitAmount" class="form-control" id="limitAmout" :placeholder="$t('enter-limit-amount')"></td>
                                    </tr>
                                 </tbody>
                              </table>
                           </div>
                        </div>
                        <div class="card-body">
                           <h6 class="card-subtitle mb-2 text-muted">{{ $t('one-click-top-up') }}</h6>
                           <div class="form-check">
                              <input class="form-check-input" type="radio" name="clickTopUp" id="clickTopUpYes" :value="true" v-model="clickTopUp">
                              <label class="form-check-label" for="clickTopUpYes">
                              {{ $t('yes') }}
                              </label>
                           </div>
                           <div class="form-check">
                              <input class="form-check-input" type="radio" name="clickTopUp" id="clickTopUpNo" :value="false" v-model="clickTopUp">
                              <label class="form-check-label" for="clickTopUpNo">
                              {{ $t('no') }}
                              </label>
                           </div>
                        </div>
                        <div class="card-body" style="padding-top: 0px;" v-if="clickTopUp">
                           <div class="form-group">
                              <label for="clickTopUpAmount">{{ $t('top-up-amount-euro') }}</label>
                              <input type="number" min="0" v-model="clickTopUpAmount" class="form-control" id="clickTopUpAmount" :placeholder="$t('enter-top-up-amount-euro')">
                           </div>
                        </div>
                        <div class="card-body" style="padding-top: 0px;">
                           <button class="btn btn-primary float-righ">{{ $t('submit') }}</button>
                        </div>
                  </div>
               </div>
            </div>
         </div>
      </layout-full-width>
   </div>
</template>

<script>
import LayoutFullWidth from "@/layouts/LayoutFullWidth";
import ComponentCarriersNavigation from '@/components/ComponentCarriersNavigation.vue';

export default {
    name: "PageDPInternetmarke",
    components: { LayoutFullWidth, ComponentCarriersNavigation },
    data() {
        return {
            email: null,
            password: null,
            onDemandTopUp: false,
            onDemandTopUpTrue: 'automatic',
            automatedTopUp: false,
            clickTopUp: false,
            treshold: null,
            automatedTopUpAmount: null,
            limitPer: 'day',
            dayLimitAmount: null,
            weekLimitAmount: null,
            monthLimitAmount: null,
            yearLimitAmount: null,
            clickTopUpAmount: null
        }
    },
    methods: {

    }
}
</script>

<style scoped>
.checkbox-submenu {
   margin-left: 20px;
   margin-top: 5px;
}

.table-body {
   border-bottom: 1px solid #dee2e6;
}
</style>